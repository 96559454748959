/*
 |-----------------------------------------------------------------------------
 | components/molecules/Loader/Loader.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect, useState } from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import * as ILoader from './types';

const Loader: FC<ILoader.IProps> = ({ storybook }) => {
	// ray('Debug atom Quote:', {
	// 	storybook: storybook,
	// }).blue();

	const router = useRouter();

	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const handleStart = url =>
			url !== get(router, 'asPath') && setLoading(true);

		const handleComplete = url =>
			url === get(router, 'asPath') &&
			setTimeout(() => {
				setLoading(false);
			}, 2000);

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleComplete);
		router.events.on('routeChangeError', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleComplete);
			router.events.off('routeChangeError', handleComplete);
		};
	});

	if (!loading && !storybook) {
		return null;
	}

	return (
		<div
			className={`
				bg-black
				bg-opacity-25
				fixed
				flex
				inset-0
				items-center
				justify-center
				z-50
			`}
			data-testid="loader"
		>
			<div>
				<span className={`sr-only`}>Loading</span>

				{map([1, 2, 3, 4, 5], index => (
					<div
						key={index}
						className={`
							animate-stretch
							bg-teal-500
							h-11
							inline-block
							mx-px
							w-1.5
							${index === 2 ? 'animation-delay-1' : ''}
							${index === 3 ? 'animation-delay-2' : ''}
							${index === 4 ? 'animation-delay-3' : ''}
							${index === 5 ? 'animation-delay-4' : ''}
						`}
					></div>
				))}
			</div>
		</div>
	);
};

export default Loader;
